export const blueRaspberry = {
  id: "energyFreezeBlueRaspberry",
  backgroundColor: "#3986be",
  textColor: "#FFFFFF",
  name: "Blue Raspberry Energy Freeze",
  tagline: "Blend, Chill and Energize",
  description:
    "Introducing the f'real Blue Raspberry Energy Freeze - a frozen blend-it-yourself beverage designed to refresh and energize! Bursting with the bold, tangy flavor of blue raspberry, this invigorating drink is perfect for any time you need a boost. With 100mg of caffeine you can fuel up without the excessive buzz, thanks to a natural mix of ingredients including coconut water and green coffee extract. It's the perfect, on-the-go, coconut water based energy drink you can feel f'real-y good about!",
  ingredients:
    "COCONUT WATER, WATER, SUGAR, DEXTROSE, CONTAINS LESS THAN 2% OF THE FOLLOWING: NATURAL FLAVOR, NATURAL AND ARTIFICIAL FLAVOR, CITRIC ACID, GUAR GUM, GUM ACACIA, CARBOHYDRATE GUM, COLORED WITH (SPIRULINA EXTRACT), GREEN COFFEE EXTRACT, SUCRALOSE.",
  disclaimer:
    " CONTAINS: COCONUT. MAY CONTAIN PEANUTS, OTHER TREE NUTS, EGGS, MILK, SOY, AND WHEAT FROM MANUFACTURING AND BLENDING.",
  nutritionFacts: {
    servingSize: "12 fl oz (355 mL) as prepared",
    calories: "180",
    values: [
      {
        type: "primary",
        label: "Total Fat",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Saturated Fat",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Trans Fat",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "50mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Total Carbohydrate",
        value: "43g",
        percent: "16%",
      },
      {
        type: "secondary",
        label: "Dietary Fiber",
        value: "1g",
        percent: "3%",
      },
      {
        type: "secondary",
        label: "Total Sugars",
        value: "39g",
        percent: "",
      },
      {
        type: "tertiary",
        label: "Includes 37g Added Sugars",
        value: "",
        percent: "74%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Vitamin D",
        value: "0.1mcg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "10mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.1mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "200mg",
        percent: "4%",
      },
    ],
  },
}

export const strawberryWatermelon = {
  id: "energyFreezeStrawberryWatermelon",
  backgroundColor: "#DA1929",
  textColor: "#FFFFFF",
  name: "Strawberry Watermelon Energy Freeze",
  tagline: "Refreshing Flavor in Every Sip",
  description:
    "Introducing the f'real Strawberry Watermelon Energy Freeze - a refreshing frozen blend-it-yourself beverage crafted to energize your day! Bursting with the sweet, vibrant flavors of ripe strawberries and juicy watermelon, this delicious treat delivers 100mg of caffeine without the jitters, thanks to its natural ingredients like coconut water and green coffee extract. It's the ultimate on-the-go, coconut water based energy drink you can feel f'real-y good about! Perfect for recharging anytime, anywhere!",
  ingredients:
    "COCONUT WATER, WATER, SUGAR, DEXTROSE, STRAWBERRY PUREE, CONTAINS LESS THAN 2% OF THE FOLLOWING: WATERMELON PUREE, CITRIC ACID, GUAR GUM, NATURAL FLAVOR, GUM ACACIA, COLORED WITH (RED BEET JUICE CONCENTRATE), CARBOHYDRATE GUM, GREEN COFFEE EXTRACT,SUCRALOSE, ASCORBIC ACID.",
  disclaimer:
    "CONTAINS: COCONUT. MAY CONTAIN PEANUTS, OTHER TREE NUTS, EGGS, MILK, SOY, AND WHEAT FROM MANUFACTURING AND BLENDING.",
  nutritionFacts: {
    servingSize: "12 fl oz (355 mL) as prepared",
    calories: "190",
    values: [
      {
        type: "primary",
        label: "Total Fat",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Saturated Fat",
        value: "0g",
        percent: "0%",
      },
      {
        type: "secondary",
        label: "Trans Fat",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "55mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Total Carbohydrate",
        value: "47g",
        percent: "17%",
      },
      {
        type: "secondary",
        label: "Dietary Fiber",
        value: "1g",
        percent: "4%",
      },
      {
        type: "secondary",
        label: "Total Sugars",
        value: "43g",
        percent: "",
      },
      {
        type: "tertiary",
        label: "Includes 40g Added Sugars",
        value: "",
        percent: "79%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Vitamin D",
        value: "0mcg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "20mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.2mg",
        percent: "2%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "260mg",
        percent: "6%",
      },
    ],
  },
}
