import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Flavor from "../../components/Flavor/"
import {
  TypeTitle,
  TypeDescription,
  CardRow,
} from "../../components/Flavor/Card"

import {
  blueRaspberry,
  strawberryWatermelon,
} from "../../data/energyFreezeData"

export default function EnergyFreezes(props) {
  const { activeFlavor, setActiveFlavor, incomingFlavor } = props

  const images = useStaticQuery(graphql`
    query flavorsEnergyFreezes {
      blueRaspberry: file(
        relativePath: {
          eq: "front-page-flavors/energy-freeze-blue-raspberry.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 300
            placeholder: BLURRED
            quality: 100
            layout: FIXED
          )
        }
      }

      strawberryWatermelon: file(
        relativePath: {
          eq: "front-page-flavors/energy-freeze-strawberry-watermelon.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            height: 300
            placeholder: BLURRED
            quality: 100
            layout: FIXED
          )
        }
      }
    }
  `)

  return (
    <>
      <TypeTitle marginTop="5rem">Energy Freeze</TypeTitle>
      <TypeDescription>
        Packed with the caffeine equivalent of more than one cup of coffee,
        Energy Freeze is designed to refresh and energize! It's the perfect
        on-the-go energy drink that you can feel f'real-y good about!
      </TypeDescription>

      <CardRow>
        <Flavor
          data={blueRaspberry}
          imageData={images.blueRaspberry.childImageSharp.gatsbyImageData}
          flavorIndex={11}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={strawberryWatermelon}
          imageData={
            images.strawberryWatermelon.childImageSharp.gatsbyImageData
          }
          flavorIndex={12}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />
      </CardRow>
    </>
  )
}
